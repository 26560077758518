<template>
  <div>
    <el-dialog title="找回密码" :visible.sync="dialogVisible" width="600px">
      <div>
        <el-steps :active="active" align-center>
          <el-step title="身份认证"></el-step>
          <el-step title="重置密码"></el-step>
        </el-steps>
      </div>
      <div class="getverfiy" v-if="active == 0">
        <div class="Mtp20">
          <img src="@/assets/supplier/phone-verfiy.png" />
          <div class="Mtp15">使用手机短信验证码找回密码</div>
          <div class="Mtp40 yanzheng co_7474">
            <el-form :model="forgetForm" :rules="forgetRules" ref="forgetForm">
              <el-form-item prop="mobile">
                <el-row :gutter="17">
                  <el-col :span="16">
                    <el-input
                      class="input"
                      v-model="forgetForm.mobile"
                      placeholder="请输入您的手机号码"
                    >
                      <span
                        slot="prefix"
                        class="iconfont icon-shouji loginiconfont"
                      ></span>
                    </el-input>
                  </el-col>
                  <el-col :span="7">
                    <verfiy-btn
                      @sendKey="getKey($event)"
                      :mobile="forgetForm.mobile"
                    ></verfiy-btn>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  v-model="forgetForm.code"
                  placeholder="请输入您的验证码"
                >
                  <span
                    slot="prefix"
                    class="iconfont icon-yanzhengma loginiconfont"
                  ></span>
                </el-input> </el-form-item
            ></el-form>
          </div>
          <div class="Mtp50">
            <el-button type="primary" @click="mobileAuth()">提交认证</el-button>
          </div>
        </div>
      </div>
      <div class="getverfiy" v-if="active == 1">
        <el-form
          class="Mtp40"
          ref="pwdChangeForm"
          :rules="pwdChangeFormRule"
          :model="pwdChangeForm"
        >
          <el-form-item prop="newPassword">
            <el-input
              show-password
              v-model="pwdChangeForm.newPassword"
              placeholder="请输入新密码"
            >
              <span
                slot="prefix"
                class="iconfont icon-mima loginiconfont"
              ></span>
            </el-input>
          </el-form-item>
          <el-form-item prop="pwdComfire">
            <el-input
              show-password
              v-model="pwdChangeForm.pwdComfire"
              placeholder="请再次确定新密码"
            >
              <span
                slot="prefix"
                class="iconfont icon-mima loginiconfont"
              ></span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" type="primary" @click="forgetThePassword()"
              >确认重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button>取 消</el-button>
        <el-button type="primary">下一步</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { checkMobileCodeByKey } from "@/api/user";
import VerfiyBtn from "@/components/verfiyBtn/verfiyBtn";
import { forgetThePassword } from "@/api/user";
let validatePass2 = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请再次输入密码"));
  } else if (value !== this.pwdChangeForm.newPassword) {
    callback(new Error("两次输入密码不一致!"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      active: 1,
      dialogVisible: false,
      key: "",
      forgetForm: {
        mobile: "",
        code: "",
      },
      forgetRules: {
        mobile: [
          { required: true, message: "请输入您的手机号码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入您的验证码", trigger: "blur" },
        ],
      },
      pwdChangeForm: {
        newPassword: "",
        pwdComfire: "",
      },
      pwdChangeFormRule: {
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "change" },
        ],
        pwdComfire: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
      },
    };
  },
  components: { VerfiyBtn },
  methods: {
    // 打开弹窗
    showModal() {
      this.active = 0;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.forgetForm = {
          mobile: "",
          code: "",
        }
        this.$refs.forgetForm.resetFields();
      });
    },
    closeModal() {
      this.dialogVisible = false;
    },
    // 验证码回调
    getKey(val) {
      this.key = val;
    },
    // 手机号认证
    mobileAuth() {
      this.$refs["forgetForm"].validate((valid) => {
        if (valid) {
          //key和输入的验证码进行对比
          checkMobileCodeByKey(this.forgetForm.code || "", this.key || "").then(
            (res) => {
              if (res.success) {
                this.$message.success(res.msg);
                // 认证成功 跳转到下一步
                this.active = 1;
                this.$nextTick(() => {
                  this.$refs.pwdChangeForm.resetField();
                });
              } else {
                this.$message.error(res.msg);
              }
            }
          );
        }
      });
    },
    // 密码重置
    forgetThePassword() {
      if (
        this.pwdChangeForm.newPassword == "" &&
        this.pwdChangeForm.pwdComfire == ""
      ) {
        this.$message.error("请输入密码");
        return false;
      }
      if (this.pwdChangeForm.newPassword != this.pwdChangeForm.pwdComfire) {
        this.$message.error("两次密码不一致");
        return false;
      }
      forgetThePassword(
        this.forgetForm.mobile,
        this.pwdChangeForm.newPassword
      ).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.active = 2;
          this.closeModal();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.getverfiy {
  width: 400px;
  margin: auto;
}
</style>