<template>
  <div id="login" class="bg_fff">
    <div class="top">
      <span class="hello orange">您好！欢迎登录</span>
      <div class="role">
        <slot name="role"></slot>
      </div>
    </div>
    <div class="center">
      <slot name="center"></slot>
    </div>
    <div class="bottom">
      <div class="otherline">
        <div class="other main-text bg_fff">其他登录方式</div>
        <div class="line b-bottom"></div>
      </div>
      <div class="icon">
        <slot name="icon1"></slot>
        <div class="icon2">
          <slot name="icon2"></slot>
        </div>
      </div>
      <div class="">
        <slot name="loginbtn">
          <el-button style="width: 100%" type="primary" @click="login"
            >登录</el-button
          >
        </slot>
      </div>
      <div class="zuce mian-text">
        <el-link @click="passWrodReset()">忘记密码？</el-link>
        <span style="float: right">
          未有账户，立即
          <router-link :to="{ name: 'RoleChoose' }" style="color: #00f; font-size: 12px " tag="a"
            >注册</router-link
          >
        </span>
      </div>
    </div>
    <forgetPassword ref="forgetPassword"></forgetPassword>
  </div>
</template>
<script>
import forgetPassword from "./forgetPassword"
export default {
  name: "Logins",
  data() {
    return {};
  },
  components: { forgetPassword },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.login();
      }
    },
    login() {
      this.$emit("loginSubmit");
    },
    // 密码找回、忘记密码
    passWrodReset() {
      this.$refs.forgetPassword.showModal();
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>
<style lang="less" scoped>
#login {
  box-sizing: border-box;
  padding: 35px 150px;
  width: 690px;
  text-align: center;
  .top {
    .hello {
      font-size: 26px;
      font-weight: bold;
    }
    .role {
      margin-top: 10px;
      margin-bottom: 35px;
    }
  }
  .center {
    padding-top: 5px;
    min-height: 250px;
  }
  .bottom {
    .otherline {
      position: relative;
      .other {
        position: absolute;
        font-size: 14px;
        padding: 2px 20px;
        top: -12px;
        left: 135px;
      }
    }
    .icon {
      padding: 25px;
      .icon2 {
        display: inline-block;
        margin-left: 30px;
      }
    }
    .zuce {
      margin-top: 10px;
      text-align: left;
      font-size: 12px;
    }
  }
}
</style>