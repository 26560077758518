<template>
  <el-button type="primary" :disabled="(disabled = !show)" @click="getVerfiy()"
    ><span>获取验证码</span><strong v-if="!show" class="F14">|</strong>
    <span v-show="!show" class="count">{{ Verfiycount }}s</span></el-button
  >
</template>
<script>
import { mapGetters } from "vuex";
import { getMobileCode } from "@/api/user";

export default {
  props: {
    mobile: {
      type: String,
    },
  },
  data() {
    return {
      show: true,
      Verfiycount: "", // 初始化次数
      timer: null,
      key: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    //获取验证码
    getVerfiy() {
      if (this.mobile == "") {
        this.$message.error("请输入手机号码！");
        return false;
      }
      //发送手机验证码获取key
      // getMobileCode(this.userInfo.mobile).then((res) => {
      getMobileCode(this.mobile).then((res) => {
        if (res.success) {
          this.key = res.data;
          console.log(this.key);
          this.$emit("sendKey", this.key);
          //获取验证码成功之后倒计时
          const TIME_COUNT = 60; //更改倒计时时间
          if (!this.timer) {
            this.Verfiycount = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.Verfiycount > 0 && this.Verfiycount <= TIME_COUNT) {
                this.Verfiycount--;
              } else {
                this.show = true;
                clearInterval(this.timer); // 清除定时器
                this.timer = null;
              }
            }, 1000);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-button {
  width: 125px;
}
</style>
